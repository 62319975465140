shaka.ui.TheatreButton = class extends shaka.ui.Element {
    constructor(parent, controls) {
        super(parent, controls);
        this.button_ = document.createElement('img');
        this.button_.id = "theatre"
        this.button_.src = "/assets/theatre.svg"
        this.button_.classList.add('pointer');
        this.button_.classList.add('theatre');
        this.button_.textContent = 'theatre';
        this.parent.appendChild(this.button_);
    }
};
shaka.ui.TheatreButton.Factory = class {
    create(rootElement, controls) {
        return new shaka.ui.TheatreButton(rootElement, controls);
    }
};
shaka.ui.Controls.registerElement('theatre', new shaka.ui.TheatreButton.Factory());

shaka.ui.CaptionsButton = class extends shaka.ui.Element {
    constructor(parent, controls) {
        super(parent, controls);
        this.button_ = document.createElement('img');
        this.button_.id = "captions"
        this.button_.src = "/assets/cc-off.svg"
        this.button_.classList.add('pointer');
        this.button_.classList.add('captions');
        this.button_.textContent = 'captions';
        this.parent.appendChild(this.button_);
    }
};
shaka.ui.CaptionsButton.Factory = class {
    create(rootElement, controls) {
        return new shaka.ui.CaptionsButton(rootElement, controls);
    }
};
shaka.ui.Controls.registerElement('captions', new shaka.ui.CaptionsButton.Factory());

shaka.ui.ReportButton = class extends shaka.ui.Element {
    constructor(parent, controls) {
        super(parent, controls);
        this.button_ = document.createElement('img');
        this.button_.id = "report"
        this.button_.src = "/assets/report.svg"
        this.button_.classList.add('pointer');
        this.button_.classList.add('report');
        this.button_.setAttribute('ngbTooltip', 'Report video playback');
        this.button_.textContent = 'report';
        this.parent.appendChild(this.button_);
    }
};
shaka.ui.ReportButton.Factory = class {
    create(rootElement, controls) {
        return new shaka.ui.ReportButton(rootElement, controls);
    }
};
shaka.ui.Controls.registerElement('report', new shaka.ui.ReportButton.Factory());


function timestampClicked(mediaId, time) {
    document.getElementById("video").currentTime = time;

    if ('URLSearchParams' in window) {
        var searchParams = new URLSearchParams(window.location.search);
        searchParams.set("t", time);
        window.history.replaceState(null, document.title, "video/" + mediaId + "?" + searchParams.toString());
    }
}
